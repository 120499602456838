/* **************** Global Styles ********************* */

:root {
  --primary-color: #673ab7;
}

/* **************** Button Styles ********************* */

.btn {
  width: auto;
  border-radius: 2rem;
  border: 1px solid var(--primary-color);
}

.btn-primary {
  color: var(--primary-color);
  background: none;
}

.btn-primary:hover {
  background: var(--primary-color);
  border: none;
}

.btn-secondary {
  height: auto;
  color: #fff;
  background: var(--primary-color);
}

.btn-secondary:hover {
  color: var(--primary-color);
  background: none;
  border: 1px solid var(--primary-color);
}

/* **************** Navbar Styles ********************* */

.logout-btn {
  border: none;
  text-decoration: none;
  color: #384152;
  width: 6rem;
  transition: background 0.3s, color 0.3s;
  float: right;
}

.logout-btn:hover {
  background: var(--primary-color);
  cursor: pointer;
  color: #fff;
}

.brandColor {
  color: var(--primary-color);
}

.navbar-nav .nav-link.active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-bottom: 0;
}

/* **************** Navbar Styles Ended ********************* */

/* ******************* Login Page Styling ******************* */

.loginImage {
  width: 90%;
  max-width: 600px;
}

/* ******************* Login Page Styling Ended ******************* */

/* ****************** AllPolls Styles ************************  */

#filter-btn {
  border: none;
  border-radius: 15px;
  background: #176b8733;
  color: black;
  margin: 4px;
  padding: 10px;
}

.card-1 {
  background: #fd8575;
  border: none;
}
.card-2 {
  background: #6a8abd;
  border: none;
}
.card-3 {
  background: #59b6df;
  border: none;
}

.btn-veiw {
  background: #1d192b1f;
  border-radius: 3rem;
  padding: 2px;
  border: none;
  width: 4rem;
  color: black;
  font-size: small;
}

#fontSize {
  font-size: 0.8rem;
}

.bgColor {
  cursor: pointer !important;
  color: black !important;
  background-color: lightgray;
}

.backGround {
  cursor: pointer !important;
  background-color: rgba(211, 211, 211, 0.3) !important;
  color: black !important;
}

/* *********************** Modal Styling **********************  */

/* Add this CSS to your stylesheet or component */
.custom-modal {
  max-width: 300px; /* Adjust the width as needed */
  width: 90%; /* Adjust the width as needed */
  margin: 0 auto;
}

.tooltip-custom {
  width: "auto !important";
}

/* ******************** Create Poll Styling ****************************  */

.inputStyle {
  border-radius: 2rem;
  height: 50px;
}

.back-arrow {
  border: none;
}
.back-arrow:hover {
  background: lightgray;
}

.disable-input > .rti--container > .rti--tag:first-of-type {
  pointer-events: none;
  background-color: lightgray;
}

.createAlert {
  position: fixed;
  width: 80%;
  z-index: 44;
}

/* *************************** Vote Section Styling ************************  */

.disabled-button {
  background-color: gray !important;
  border: none !important;
}

.shareLinked {
  border-radius: 20px !important;
  width: 90% !important;
}

/* ***************** Share Modal ******************  */

.border-radius {
  border: 1px solid #673ab733;
  border-radius: 20px !important;
  background: #673ab733;
}

/* ****************************** Voters List **************************  */
.custom-hover:hover {
  background-color: lightgray;
}

.input-border {
  border: none;
}

.input-border:focus {
  outline: none;
  border: none;
}
